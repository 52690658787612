import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { FaAngleLeft, FaEdit, FaPlus, FaTrash, FaTelegramPlane } from 'react-icons/fa'
import { Avatar, ListItemAvatar, InputAdornment, IconButton } from '@mui/material'
import { Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material'

import PartnerAvatar from 'components/PartnerAvatar'
import Button from 'components/Button'
import Card, { CardGrid } from 'components/Card'
import Container from 'components/Container'
import Spinner from 'components/Spinner'

import TWControls from 'components/controls/TWControls'
import useMediaQuery from 'hooks/useMediaQuery'

import Styled from './Layout.style'
import * as _ from 'styles/helpers'
import { styled as muiStyled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

const LightTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#0080fe',
		boxShadow: theme.shadows[5],
		fontSize: 14,
	},
}))

export interface Props {
	className?: string
	children: any
}

export interface LayoutRightbarProps extends Props {
	isOffset?: boolean
}

export interface LayoutRightbarItemProps extends Props {
	title?: string
	icon?: React.ReactNode
	contentRight?: React.ReactNode
	isEmpty?: boolean
	hasNoMaxHeight?: boolean
	noPadding?: boolean
	noElevation?: boolean
}

export interface LayoutRightbarTopProps extends Props {
	img: string
	title: string
	link?: string
}

export interface LayoutSidebarProps extends Props {
	isOffset?: boolean
}

export interface LayoutSidebarItemProps extends Props {
	title?: string
	icon?: React.ReactNode
	contentRight?: React.ReactNode
	isEmpty?: boolean
	hasNoMaxHeight?: boolean
	noPadding?: boolean
	noElevation?: boolean
}

export interface LayoutSidebarTopProps extends Props {
	img: string
	title: string
	link?: string
}

export interface LayoutSidebarTop2Props extends Props {
	img: string
	title: string
	project: string
	link?: string
	isOnline: boolean
}

export interface LayoutPartnerProps {
	className?: string
	avatar: string
	name: string
	title: string
	company?: string
	link: string
	isOwner?: boolean
	isPortfolio?: boolean
	isOnline?: boolean
}

export interface LayoutPartnerRemoveProps {
	className?: string
	avatar: string
	name: string
	title: string
	company?: string
	link: string
	isOwner?: boolean
	isOnline?: boolean
}

export interface LayoutPartnershipProps {
	className?: string
	avatar: string
	name: string
	title: string
	company?: string
	buttonText: string
	buttonOnClick: Function
	isOwner?: boolean
	isOnline?: boolean
}

export interface LayoutPageToolbarProps {
	className?: string
	buttonText: string
	buttonOnClick: Function
	searchInputRef: any // what is ref in TS?
	searchInputValue: string
	searchInputOnChange: Function
}
export interface LayoutPageToolbar_NoIconProps {
	className?: string
	buttonText: string
	buttonOnClick: Function
	searchInputRef: any // what is ref in TS?
	searchInputValue: string
	searchInputOnChange: Function
}
export interface LayoutPageToolbar_NoPlusProps {
	className?: string
	searchInputRef: any // what is ref in TS?
	searchInputValue: string
	searchInputOnChange: Function
}
export interface LayoutFirstTimeContentProps extends Props {
	title?: string
	icon?: React.ReactNode
	contentRight?: React.ReactNode
	isEmpty?: boolean
	hasNoMaxHeight?: boolean
	noPadding?: boolean
	noElevation?: boolean
}

export interface LayoutProfileUserCardProps {
	className?: string
}
export interface LayoutFirstProfileUserCardProps {
	className?: string
}
export interface LayoutFirstLocationCardProps {
	className?: string
}
export interface LayoutFirstProjectCardProps {
	className?: string
}
export interface LayoutFirstPartnerCardProps {
	className?: string
}
export interface LayoutSidebarPartnersProps {
	className?: string
}

export interface LayoutSidebarUserCardProps {
	className?: string
}

export interface LayoutSidebarAssetTopProps {
	className?: string
	assetName: string
	address: ReactNode
	webAddress?: string
	image: string
	originator: string
	project?: string
}

const Layout = ({ children, className = '' }: Props) => (
	<Styled.Layout data-testid="Layout" className={className}>
		{children}
	</Styled.Layout>
)

const LayoutPage = ({ children, className = '' }: Props) => (
	<div data-testid="Layout.Page" className={`${className} Layout__Page`.trim()}>
		<Container className="Layout__Page__Container">{children}</Container>
	</div>
)

const LayoutPageHeader = ({ className = '', children }: Props) => (
	<Styled.LayoutPageHeader data-testid="Layout.PageHeader" className={className}>
		<div className="PageHeader__content">{children}</div>
	</Styled.LayoutPageHeader>
)

const LayoutPageToolbar = ({
	className = '',
	buttonText,
	buttonOnClick,
	searchInputRef,
	searchInputOnChange,
	searchInputValue,
}: LayoutPageToolbarProps) => {
	const isMobile = useMediaQuery(_.MQ.before_nav)
	const LayoutPageToolbarWrapper = ({ children }: { children: any }) => {
		if (isMobile) return <CardGrid>{children}</CardGrid>
		return children
	}

	return (
		<Styled.LayoutPageToolbar
			data-testid="Layout.PageToolbar"
			className={[className, isMobile ? 'PageToolbar--isMobile' : ''].join(' ').trim()}
		>
			<LayoutPageToolbarWrapper>
				<TWControls.Input
					inputRef={searchInputRef}
					// label="Search"
					type="search"
					size="small"
					value={searchInputValue}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onChange={searchInputOnChange}
				/>
				<Button variant="text" isInput onClick={buttonOnClick}>
					<FaPlus /> {buttonText}
				</Button>
			</LayoutPageToolbarWrapper>
		</Styled.LayoutPageToolbar>
	)
}

const LayoutPageToolbar_NoIcon = ({
	className = '',
	buttonText,
	buttonOnClick,
	searchInputRef,
	searchInputOnChange,
	searchInputValue,
}: LayoutPageToolbar_NoIconProps) => {
	const isMobile = useMediaQuery(_.MQ.before_nav)
	const LayoutPageToolbarWrapper = ({ children }: { children: any }) => {
		if (isMobile) return <CardGrid>{children}</CardGrid>
		return children
	}

	return (
		<Styled.LayoutPageToolbar
			data-testid="Layout.PageToolbar"
			className={[className, isMobile ? 'PageToolbar--isMobile' : ''].join(' ').trim()}
		>
			<LayoutPageToolbarWrapper>
				<TWControls.Input
					inputRef={searchInputRef}
					// label="Search"
					type="search"
					size="small"
					value={searchInputValue}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onChange={searchInputOnChange}
				/>
				<Button variant="text" isInput onClick={buttonOnClick}>
					{buttonText}
				</Button>
			</LayoutPageToolbarWrapper>
		</Styled.LayoutPageToolbar>
	)
}

const LayoutSecondaryNav = ({
	className = '',
	links,
	backLink,
}: {
	className?: string
	links: Partial<{ linkText: string; linkTo: string }>[]
	backLink?: {
		link: string
		title: string
	}
}) => {
	const match = useMediaQuery(_.MQ.nav)
	return (
		<Styled.LayoutSecondaryNav data-testid="Layout.SecondaryNav" className={className}>
			<Container className="SecondaryNav__Container">
				{match && backLink?.link && backLink?.title && (
					<div className="SecondaryNav__backlink">
						<Link to={backLink.link}>
							<FaAngleLeft /> {backLink.title}
						</Link>
					</div>
				)}
				<nav className="SecondaryNav__links">
					<div className="SecondaryNav__links__scroll-container">
						{links.map(
							(link, index) =>
								link.linkTo &&
								link.linkText && (
									<NavLink
										key={index}
										to={link.linkTo}
										className="SecondaryNav__link"
										activeClassName="is-active"
									>
										{link.linkText}
									</NavLink>
								),
						)}
					</div>
				</nav>
			</Container>
		</Styled.LayoutSecondaryNav>
	)
}

const LayoutBody = ({ children, className = '' }: Props) => (
	<Styled.LayoutBody data-testid="Layout.Body" className={className}>
		{children}
	</Styled.LayoutBody>
)

const LayoutRightbar = ({ children, className = '', isOffset = false }: LayoutRightbarProps) => {
	const match = useMediaQuery(_.MQ.before_nav)
	if (match) return null

	return (
		<Styled.LayoutRightbar data-testid="Layout.Rightbar" className={className} isOffset={isOffset}>
			{children}
		</Styled.LayoutRightbar>
	)
}

const LayoutRightbarItem = ({
	children,
	className = '',
	title,
	icon,
	contentRight,
	isEmpty = false,
	hasNoMaxHeight = false,
	noPadding = false,
	noElevation = true,
}: LayoutRightbarItemProps) => (
	<Styled.LayoutRightbarItem
		data-testid="Layout.RightbarItem"
		className={className}
		isEmpty={isEmpty}
		hasNoMaxHeight={hasNoMaxHeight}
	>
		<Card
			header={
				title
					? ({ title, secondary: contentRight, icon } as any)
					: ({ secondary: contentRight, icon } as any)
			}
			noPadding={noPadding}
			noElevation={noElevation}
			isCentered={isEmpty}
			isLandscape={isEmpty}
		>
			{children}
		</Card>
	</Styled.LayoutRightbarItem>
)

const LayoutRightbarTop = ({
	children,
	className = '',
	img,
	title,
	link,
}: LayoutRightbarTopProps) => {
	const TitleWrapper = ({ children }: { children: any }) => {
		if (link) return <Link to={link}>{children}</Link>
		return children
	}

	return (
		<Styled.LayoutRightbarTop data-testid="Layout.RightbarTop" className={className} img={img}>
			<div className="body">
				<TitleWrapper>
					<h2 className="title">{title}</h2>
				</TitleWrapper>
				{children}
			</div>
			<div className="img" />
		</Styled.LayoutRightbarTop>
	)
}

const LayoutSidebar = ({ children, className = '', isOffset = false }: LayoutSidebarProps) => {
	const match = useMediaQuery(_.MQ.before_nav)
	if (match) return null

	return (
		<Styled.LayoutSidebar data-testid="Layout.Sidebar" className={className} isOffset={isOffset}>
			{children}
		</Styled.LayoutSidebar>
	)
}

const LayoutSidebarNews = ({ children, className = '' }: Props) => {
	const match = useMediaQuery(_.MQ.before_nav)
	if (match) return null

	return (
		<Styled.LayoutSidebarNews data-testid="Layout.LayoutSidebarNews" className={className}>
			{children}
		</Styled.LayoutSidebarNews>
	)
}

const LayoutSidebarPartners = ({ children, className = '' }: Props) => {
	const match = useMediaQuery(_.MQ.before_nav)
	if (match) return null

	return (
		<Styled.LayoutSidebarPartners data-testid="Layout.LayoutSidebarPartners" className={className}>
			{children}
		</Styled.LayoutSidebarPartners>
	)
}

const LayoutSidebarItem = ({
	children,
	className = '',
	title,
	icon,
	contentRight,
	isEmpty = false,
	hasNoMaxHeight = false,
	noPadding = false,
	noElevation = true,
}: LayoutSidebarItemProps) => (
	<Styled.LayoutSidebarItem
		data-testid="Layout.SidebarItem"
		className={className}
		isEmpty={isEmpty}
		hasNoMaxHeight={hasNoMaxHeight}
	>
		<Card
			header={title && ({ title, secondary: contentRight, icon } as any)}
			noPadding={noPadding}
			noElevation={noElevation}
			isCentered={isEmpty}
			isLandscape={isEmpty}
		>
			{children}
		</Card>
	</Styled.LayoutSidebarItem>
)

const LayoutSidebarTop = ({
	children,
	className = '',
	img,
	title,
	link,
}: LayoutSidebarTopProps) => {
	const TitleWrapper = ({ children }: { children: any }) => {
		if (link) return <Link to={link}>{children}</Link>
		return children
	}

	return (
		<Styled.LayoutSidebarTop data-testid="Layout.SidebarTop" className={className} img={img}>
			<div className="body">
				<TitleWrapper>
					<h2 className="title">{title}</h2>
				</TitleWrapper>
				{children}
			</div>
			<div className="img" />
		</Styled.LayoutSidebarTop>
	)
}

const LayoutSidebarTop2 = ({
	children,
	className = '',
	img,
	title,
	project,
	link,
}: LayoutSidebarTop2Props) => {
	const TitleWrapper = ({ children }: { children: any }) => {
		if (link) return <Link to={link}>{children}</Link>
		return children
	}

	return (
		<Styled.LayoutSidebarTop2 data-testid="Layout.SidebarTop2" className={className} img={img}>
			<div className="body">
				{/* <TitleWrapper>
					<LightTooltip title={<React.Fragment>{`Edit '${title}'`}</React.Fragment>}>
						<h2 className="title">{title}</h2>
					</LightTooltip>
				</TitleWrapper> */}
				<>
					{title ? (
						<h2 className="title">
							{title}
							<TitleWrapper>
								<IconButton color="primary" size="small">
									<EditIcon fontSize="inherit" />
								</IconButton>
							</TitleWrapper>
						</h2>
					) : null}
				</>
				{children}
				<div className="project">{project}</div>
			</div>
		</Styled.LayoutSidebarTop2>
	)
}

const LayoutPartnerItem = ({
	className = '',
	avatar,
	name,
	title,
	company,
	link,
	isOwner = false,
	isOnline = false,
	isPortfolio = false,
}: LayoutPartnerProps) => {
	const isMailToLink = link.startsWith('mailto')

	const LinkWrapper = ({ children }: { children: any }) => {
		if (isMailToLink) return <a href="">{children}</a>
		return <div>{children}</div>
	}

	return (
		<Styled.LayoutPartnerItem data-testid="Layout.PartnerItem" className={className}>
			<LinkWrapper>
				<div className="body">
					<div className="avatar">
						<ListItemAvatar>
							<PartnerAvatar src={avatar} name={name} size="40" isOnline={isOnline} />
						</ListItemAvatar>
					</div>
					<div className="details">
						<div className="name">
							{name}
							{/* {isMailToLink && <FaTelegramPlane />} */}
						</div>
						<div className="info">
							<div>{title}</div>
							{company && <div>{company}</div>}
						</div>
						{isOwner && (
							<div>{isPortfolio ? `(portfolio originator)` : `(project originator)`}</div>
						)}
					</div>
				</div>
			</LinkWrapper>
		</Styled.LayoutPartnerItem>
	)
}

const LayoutPartnerRemoveItem = ({
	className = '',
	avatar,
	name,
	title,
	company,
	link,
	isOwner = false,
	isOnline = false,
}: LayoutPartnerRemoveProps) => {
	// const isMailToLink = link.startsWith('mailto')

	// const LinkWrapper = ({ children }: { children: any }) => {
	// 	if (isMailToLink) return <a href={link}>{children}</a>
	// 	return <Link to={link}>{children}</Link>
	// }

	return (
		<Styled.LayoutPartnerRemoveItem data-testid="Layout.PartnerRemoveItem" className={className}>
			<div className="wrapper">
				<div className="body">
					<div className="avatar">
						<ListItemAvatar>
							<PartnerAvatar src={avatar} name={name} size="40" isOnline={isOnline} />
						</ListItemAvatar>
					</div>
					<div className="details">
						<div className="name">
							{name}
							{<FaTrash />}
						</div>
						<div className="info">
							<div>{title}</div>
							{company && <div>{company}</div>}
						</div>
						{isOwner && <div>(portfolio originator)</div>}
					</div>
				</div>
			</div>
		</Styled.LayoutPartnerRemoveItem>
	)
}

const LayoutSidebarItemLoading = ({ className = '' }: { className: string }) => (
	<Spinner className={className} containerHeight="224px" />
)

const LayoutNewsArticle = ({ children, className = '' }: Props) => (
	<Styled.LayoutNewsArticle data-testid="Layout.NewsArticle" className={className}>
		{children}
	</Styled.LayoutNewsArticle>
)

const LayoutSidebarUserCard = ({ className = '' }: LayoutSidebarUserCardProps) => {
	// @ts-ignore
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	if (!userInfo) return null

	const { _id, name, image, title, company, email } = userInfo

	return (
		<LayoutSidebarItem noPadding noElevation>
			<Styled.LayoutSidebarUserCard data-testid="Layout.SidebarUserCard" className={className}>
				<Link to="/userprofile">
					<Avatar src={image} className="LayoutSidebarUserCard__avatar" />
				</Link>
				<Link to="/userprofile">
					<h3 className="LayoutSidebarUserCard__user-name">{name}</h3>
				</Link>
				<div>
					{title && title}
					{title && company && ', '} {company && company}
				</div>
				<div style={{ fontSize: '11px' }}>{email}</div>
				<div style={{ fontSize: '11px' }}>{_id}</div>
			</Styled.LayoutSidebarUserCard>
		</LayoutSidebarItem>
	)
}

const LayoutSidebarAssetTop = ({
	className = '',
	assetName,
	address,
	webAddress,
	image,
	originator,
	project,
}: LayoutSidebarAssetTopProps) => {
	return (
		<LayoutSidebarItem noPadding noElevation>
			<Styled.LayoutSidebarAssetTop data-testid="Layout.SidebarAssetTop" className={className}>
				<div>
					<Avatar src={image} className="LayoutSidebarAssetTop__avatar" />
				</div>
				<div>
					<h3 className="LayoutSidebarAssetTop__title">{assetName}</h3>
				</div>
				<div>
					<h3 className="LayoutSidebarAssetTop__address">{address}</h3>
				</div>
				<div>
					<h3 className="LayoutSidebarAssetTop__address">{webAddress}</h3>
				</div>
				{project && (
					<div>
						<h3 className="LayoutSidebarAssetTop__project">{project}</h3>
					</div>
				)}
				<div>
					<h3 className="LayoutSidebarAssetTop__originator">{originator}</h3>
				</div>
			</Styled.LayoutSidebarAssetTop>
		</LayoutSidebarItem>
	)
}

const LayoutPageToolbar_NoPlus = ({
	className = '',
	searchInputRef,
	searchInputOnChange,
	searchInputValue,
}: LayoutPageToolbar_NoPlusProps) => {
	const isMobile = useMediaQuery(_.MQ.before_nav)
	const LayoutPageToolbarNoPlusWrapper = ({ children }: { children: any }) => {
		if (isMobile) return <CardGrid>{children}</CardGrid>
		return children
	}

	return (
		<Styled.LayoutPageToolbar_NoPlus
			data-testid="Layout.PageToolbar_NoPlus"
			className={[className, isMobile ? 'PageToolbar_NoPlus--isMobile' : ''].join(' ').trim()}
		>
			<LayoutPageToolbarNoPlusWrapper>
				<TWControls.Input
					inputRef={searchInputRef}
					// label="Search"
					type="search"
					size="small"
					value={searchInputValue}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onChange={searchInputOnChange}
				/>
			</LayoutPageToolbarNoPlusWrapper>
		</Styled.LayoutPageToolbar_NoPlus>
	)
}

const LayoutPartnershipItem = ({
	className = '',
	avatar,
	name,
	title,
	company,
	buttonText,
	buttonOnClick,
	isOwner = false,
	isOnline = false,
}: LayoutPartnershipProps) => {
	return (
		<Styled.LayoutPartnerItem data-testid="Layout.PartnerItem" className={className}>
			<div className="body">
				<div className="avatar">
					<ListItemAvatar>
						<PartnerAvatar src={avatar} name={name} size="40" isOnline={isOnline} />
					</ListItemAvatar>
				</div>
				<div className="details">
					<div className="name">
						{/* {name} */}
						<Button variant="ghost" isInput onClick={buttonOnClick}>
							{name}
							{/* {buttonText} */}
						</Button>
					</div>
					<div className="info">
						<div>{title}</div>
						{company && <div>{company}</div>}
					</div>
					{isOwner && <div>(portfolio originator)</div>}
				</div>
			</div>
		</Styled.LayoutPartnerItem>
	)
}

const LayoutFirstTimeContent = ({
	children,
	className = '',
	title,
	icon,
	contentRight,
	isEmpty = false,
	hasNoMaxHeight = false,
	noPadding = false,
	noElevation = true,
}: LayoutFirstTimeContentProps) => (
	<Styled.LayoutFirstTimeContent
		data-testid="Layout.FirstTimeContent"
		className={className}
		isEmpty={isEmpty}
		hasNoMaxHeight={hasNoMaxHeight}
	>
		<Card
			header={title && ({ title, secondary: contentRight, icon } as any)}
			noPadding={noPadding}
			noElevation={noElevation}
			isCentered={isEmpty}
			isLandscape={isEmpty}
		>
			{children}
		</Card>
	</Styled.LayoutFirstTimeContent>
)

const LayoutProfileUserCard = ({ className = '' }: LayoutProfileUserCardProps) => {
	// @ts-ignore
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	if (!userInfo) return null

	const { name, image, title, company, email } = userInfo

	return (
		<LayoutFirstTimeContent noPadding noElevation>
			<Styled.LayoutProfileUserCard data-testid="Layout.ProfileUserCard" className={className}>
				<Link to="/userprofile">
					<Avatar src={image} className="LayoutProfileUserCard__avatar" />
				</Link>
				<Link to="/userprofile">
					<h3 className="LayoutProfileUserCard__user-name">{name}</h3>
				</Link>
				<div>
					{title && title}
					{title && company && ', '} {company && company}
				</div>
				<div style={{ fontSize: '11px' }}>{email}</div>
			</Styled.LayoutProfileUserCard>
		</LayoutFirstTimeContent>
	)
}

const LayoutFirstProfileUserCard = ({ className = '' }: LayoutFirstProfileUserCardProps) => {
	// @ts-ignore
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	if (!userInfo) return null

	const { name, image, title, company, email } = userInfo

	return (
		<LayoutFirstTimeContent noPadding noElevation>
			<Styled.LayoutFirstProfileUserCard
				data-testid="Layout.FirstProfileUserCard"
				className={className}
			>
				<Link to="">
					<Avatar src={image} className="LayoutFirstProfileUserCard__avatar" />
				</Link>
				<Link to="">
					<h3 className="LayoutFirstProfileUserCard__user-name">{name}</h3>
				</Link>
				<div>
					{title && title}
					{title && company && ', '} {company && company}
				</div>
				<div style={{ fontSize: '11px' }}>{email}</div>
			</Styled.LayoutFirstProfileUserCard>
		</LayoutFirstTimeContent>
	)
}

const LayoutFirstLocationCard = ({ className = '' }: LayoutFirstLocationCardProps) => {
	// @ts-ignore
	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo } = userLogin

	const {
		entities: locations,
		ids: locationIds,
		isInitialized: locationsInitialized,
	} = useSelector((state: any) => state.locations)
	const firstLocation = locationIds.map(id => locations[id])

	if (!firstLocation[0]) return null

	const { assetName, address, city, stateProvince, image } = firstLocation[0]

	return (
		<LayoutFirstTimeContent noPadding noElevation>
			<Styled.LayoutFirstLocationCard data-testid="Layout.FirstLocationCard" className={className}>
				<Link to="">
					<Avatar src={image} className="LayoutFirstLocationCard__avatar" />
				</Link>
				<Link to="">
					<h3 className="LayoutFirstLocationCard__user-name">{assetName}</h3>
				</Link>
				<div>{address}</div>
				<div>{`${city}, ${stateProvince}`}</div>
				{/* <div style={{ fontSize: '11px' }}>{email}</div> */}
			</Styled.LayoutFirstLocationCard>
		</LayoutFirstTimeContent>
	)
}

const LayoutFirstProjectCard = ({ className = '' }: LayoutFirstProjectCardProps) => {
	// @ts-ignore
	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo } = userLogin

	const { entities: projects, ids: projectIds, isInitialized: projectsInitialized } = useSelector(
		(state: any) => state.projects,
	)
	const firstProject = projectIds.map(id => projects[id])

	if (!firstProject[0]) return null

	const { projectName, projectDescription, image } = firstProject[0]

	return (
		<LayoutFirstTimeContent noPadding noElevation>
			<Styled.LayoutFirstProjectCard data-testid="Layout.FirstProjectCard" className={className}>
				<Link to="">
					<Avatar src={image} className="LayoutFirstProjectCard__avatar" />
				</Link>
				<Link to="">
					<h3 className="LayoutFirstProjectCard__user-name">{projectName}</h3>
				</Link>
				<div>{projectDescription}</div>
				{/* <div>{`${city}, ${stateProvince}`}</div> */}
				{/* <div style={{ fontSize: '11px' }}>{email}</div> */}
			</Styled.LayoutFirstProjectCard>
		</LayoutFirstTimeContent>
	)
}

const LayoutFirstPartnerCard = ({ className = '' }: LayoutFirstPartnerCardProps) => {
	// @ts-ignore
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	if (!userInfo) return null

	const { name, image, title, company, email } = userInfo

	return (
		<LayoutFirstTimeContent noPadding noElevation>
			<Styled.LayoutFirstPartnerCard data-testid="Layout.FirstPartnerCard" className={className}>
				<Link to="/userprofile">
					<Avatar src={image} className="LayoutFirstPartnerCard__avatar" />
				</Link>
				<Link to="/userprofile">
					<h3 className="LayoutFirstPartnerCard__user-name">{name}</h3>
				</Link>
				<div>
					{title && title}
					{title && company && ', '} {company && company}
				</div>
				<div style={{ fontSize: '11px' }}>{email}</div>
			</Styled.LayoutFirstPartnerCard>
		</LayoutFirstTimeContent>
	)
}

Layout.PageHeader = LayoutPageHeader
Layout.PageToolbar = LayoutPageToolbar
Layout.PageToolbar_NoIcon = LayoutPageToolbar_NoIcon
Layout.PageToolbar_NoPlus = LayoutPageToolbar_NoPlus
Layout.SecondaryNav = LayoutSecondaryNav
Layout.Page = LayoutPage
Layout.Body = LayoutBody
Layout.Rightbar = LayoutRightbar
Layout.RightbarItem = LayoutRightbarItem
Layout.RightbarTop = LayoutRightbarTop
Layout.Sidebar = LayoutSidebar
Layout.SidebarItem = LayoutSidebarItem
Layout.SidebarItemLoading = LayoutSidebarItemLoading
Layout.SidebarTop = LayoutSidebarTop
Layout.SidebarTop2 = LayoutSidebarTop2
Layout.SidebarAssetTop = LayoutSidebarAssetTop
Layout.SidebarUserCard = LayoutSidebarUserCard
Layout.SidebarNews = LayoutSidebarNews
Layout.SidebarPartners = LayoutSidebarPartners
Layout.PartnerItem = LayoutPartnerItem
Layout.PartnerRemoveItem = LayoutPartnerRemoveItem
Layout.PartnershipItem = LayoutPartnershipItem
Layout.NewsArticle = LayoutNewsArticle
Layout.ProfileUserCard = LayoutProfileUserCard
Layout.FirstLocationCard = LayoutFirstLocationCard
Layout.FirstPartnerCard = LayoutFirstPartnerCard
Layout.FirstProfileUserCard = LayoutFirstProfileUserCard
Layout.FirstProjectCard = LayoutFirstProjectCard

export default Layout
