import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	RiCheckboxBlankCircleLine,
	RiCheckboxCircleFill,
	RiCloseCircleLine,
	RiAddLine,
	RiEyeLine,
	RiEyeOffLine,
} from 'react-icons/ri'
import moment from 'moment'
import Button from 'components/Button'
import StyledTask from './Task.style'
import Popup from 'components/Popup'
import TaskAddPopup from 'components/popups/TaskAddPopup'
import TaskAddEditPopup from 'components/popups/TaskAddEditPopup'
import { emailTaskUpdate } from 'functions/taskEmails'
import { initializeTasks } from 'slices/tasksSlice'
import PartnerAvatar from 'components/PartnerAvatar'
import { Avatar, Stack } from '@mui/material'
import swal from 'sweetalert'
import SendEmailPopup from 'components/popups/SendEmailPopup'
import { emailTaskPopup } from 'functions/userEmails'

// const keys = {
// 	ENTER: 'Enter',
// 	ESC: 'Escape',
// }

interface Partner {
	assignedTo: string // partner id
	assignee: string // partner name
}

interface Task {
	children?: any
	id: string
	userId: string
	packageid: string
	portfolioid?: string | null
	projectid: string
	packageType: string
	title: string
	taskName: string
	dueDate: string
	completedBy: string
	dateStarted: string
	dateCompleted: string
	isComplete: boolean
	assignedTo?: string | null
	assignedToName?: string | null
	assignedToEmail?: string | null
	firstName?: string | null
	image: any | null
	description: string
	taskStatus: string
	comments: Comment[]
	user: any
	location: any
	project: any
	partners: Partner[]
	handleCreateTask: Function
	handleUpdateTask: Function
	handleDeleteTask: Function

	// subtask-only
	parentId?: string
	seq?: number

	// unused
}

export interface Props extends Task {}

const Task = ({
	id,
	userId,
	packageid,
	portfolioid,
	projectid,
	packageType,
	title,
	taskName,
	dueDate,
	completedBy,
	dateStarted,
	dateCompleted,
	isComplete = false,
	assignedTo,
	assignedToName = 'none',
	assignedToEmail,
	firstName,
	image,
	description,
	taskStatus,
	comments,
	user,
	location,
	project,
	partners,
	handleCreateTask,
	handleUpdateTask,
	handleDeleteTask,
	children,
	parentId,
	seq,
}: Props) => {
	// const taskTitleInput = useRef(null)
	const dispatch = useDispatch()

	const [task /*setTask*/] = useState({
		id,
		userId,
		packageid,
		portfolioid,
		projectid,
		packageType,
		title,
		taskName,
		dueDate,
		completedBy,
		dateStarted,
		dateCompleted,
		isComplete,
		assignedTo,
		assignedToName,
		assignedToEmail,
		firstName,
		image,
		description,
		taskStatus,
		partners,
		comments,
		user,
		location,
		project,
		parentId,
		seq,
	} as Task)

	const [newSubtask, setNewSubtask] = useState(false)
	const [openPopup, setOpenPopup] = useState(false)
	const [openAddPopup, setOpenAddPopup] = useState(false)
	const [emailRecord, setEmailRecord] = useState(null)
	const [openEmailPopup, setOpenEmailPopup] = useState(false)
	const [parentid, setParentid] = useState('')
	const [popupTitle, setPopupTitle] = useState('Review Task')
	const [recordForEdit, setRecordForEdit] = useState<{} | null>(null)

	const [subtasksAreVisible, setSubtasksAreVisible] = useState(true)

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const isSubtask = !!parentId
	const hasSubtasks = children && children.length > 0

	const component = (() => (isSubtask ? 'Subtask' : 'Task'))()

	const cls = (className: string = '', addIsCompleteClass: boolean = false) => {
		let _className = `Task__${className}`
		if (addIsCompleteClass && task.isComplete)
			_className = `${_className} ${_className}--isComplete`
		return _className.trim()
	}
	const openInPopup = task => {
		console.log(`task: `, task)
		setRecordForEdit(task)
		setOpenPopup(true)
	}

	const addEditTask = async (task, resetForm) => {
		if (task.taskStatus === 'Completed') {
			swal({
				title: `Task Completed!`,
				text: `This will set the current task to Completed. Once completed tasks can no longer be updated, or modified.`,
				icon: 'warning',
				buttons: {
					cancel: {
						text: 'Cancel',
						value: null,
						visible: true,
						className: '',
						closeModal: true,
					},
					confirm: {
						text: 'Confirm',
						value: true,
						visible: true,
						className: '',
						closeModal: true,
					},
				},
			}).then(willComplete => {
				if (willComplete) {
					swal({
						title: `Task Completed!`,
						text: `This task has now been completed.`,
						icon: 'success',
					})

					handleUpdateTask(task)
				}
			})
		} else {
			if (newSubtask) {
				handleCreateTask(task)
				setNewSubtask(false)
			} else {
				handleUpdateTask(task)
			}
		}

		// const pushLink = `http://localhost:3000/registerconfirm/?id=${newId}&name=${newUser.name}&firstname=${newUser.firstName}&email=${newUser.email}`

		// dispatch(emailTaskUpdate({ originalTask: recordForEdit, newTask: task, location, user }))

		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const CompletedDays = ({ task }) => {
		const completedBy = task.completedBy
		const completedDate = task.dateCompleted
		const timezoneOffset = new Date().getTimezoneOffset() / 60
		const hoursOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'hours')
		let daysOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'days')

		if (hoursOut < 0) {
			daysOut = daysOut - 1
		}

		const daysLable = daysOut > 1 || daysOut < -1 ? `days` : `day`
		const result =
			daysOut === 0
				? 'Today'
				: daysOut > 0
				? `Due: ${daysOut} ${daysLable}`
				: `${daysOut * -1} ${daysLable} late!`

		return task.isComplete ? (
			<div>{moment(completedDate).format('MM-DD-YYYY')}</div>
		) : (
			<div style={{ color: daysOut < 0 ? 'red' : 'inherit' }}>{result}</div>
		)
	}

	const handelNewSubtask = () => {
		const newDate = {
			currentDate: new Date(), // Today
			futureDays: 7, // Days to add
		}

		const newTaskDate = new Date(
			new Date(newDate.currentDate).setDate(newDate.currentDate.getDate() + newDate.futureDays),
		)

		const newTask = {
			_id: 0,
			assignedTo: user._id,
			parentid: task.id,
			assignedToName: user.name,
			assignedToEmail: user.email,
			completedBy: newTaskDate,
		}

		setPopupTitle('New Subtask')
		setRecordForEdit(newTask)
		setParentid(task.id)
		setOpenAddPopup(true)
		setNewSubtask(true)
	}

	const stringToColor = (string: string) => {
		let hash = 0
		let i

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash)
		}

		let color = '#'

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff
			color += `00${value.toString(16)}`.slice(-2)
		}
		/* eslint-enable no-bitwise */

		return color
	}

	const stringAvatar = (avatarName: string) => {
		if (avatarName.indexOf(' ') > 0) {
			return {
				sx: {
					bgcolor: stringToColor(avatarName),
					width: 30,
					height: 30,
					fontSize: 14,
				},
				children: `${avatarName.split(' ')[0][0]}${avatarName.split(' ')[1][0]}`,
			}
		} else {
			return {
				sx: {
					bgcolor: stringToColor(avatarName),
					width: 30,
					height: 30,
					fontSize: 14,
				},
				children: `uk`,
			}
		}
	}

	const emailPartnersHandler = async task => {
		const emailKit = {
			fromName: user.name,
			fromFirstName: user.firstName,
			fromEmail: user.email,
			toName: task.assignedToName,
			toFirstName: task.firstName,
			toEmail: task.assignedToEmail,
			taskName: task.taskName,
			taskLocation: location.assetName,
			taskProject: project.projectName,
			taskDue: moment(task.completedBy).format('MM/DD/YYYY'),
			taskStatus: task.taskStatus,
			taskNotes: task.description,
			emailSubject: `${location.assetName} | ${project.projectName} | ${task.taskName}`,
			emailBody: '',
		} as any

		setEmailRecord(emailKit)
		setPopupTitle('Compose Email . . .')
		setOpenEmailPopup(true)
	}

	const sendEmailHandler = emailKit => {
		const pushLink = `${SERVER_BASE_URL}/locations/${location._id}/projects/${project._id}/tasks`
		emailTaskPopup(emailKit, pushLink)
		swal(`Email Sent!`, `An email has been sent to ${emailKit.toName}.`, 'success')

		setOpenEmailPopup(false)
	}

	return (
		<StyledTask data-testid={component} className={component} tabIndex={0}>
			<div className={cls('item', true)}>
				<div
					className={cls('complete')}
					onClick={() => {
						setPopupTitle(`Task: ${task.title}`)
						openInPopup(task)
					}}
				>
					{task.isComplete ? <RiCheckboxCircleFill /> : <RiCheckboxBlankCircleLine />}
				</div>
				<div className={cls('title')}>
					<button
						className={cls('title-button', true)}
						onClick={() => {
							setPopupTitle(`Task: ${task.title}`)
							openInPopup(task)
						}}
					>
						{task.title}
					</button>
				</div>
				<div
					className={cls('status')}
					onClick={() => {
						setPopupTitle(`Task: ${task.title}`)
						openInPopup(task)
					}}
				>
					{task.taskStatus}
				</div>
				<div
					className={cls('date')}
					onClick={() => {
						setPopupTitle(`Task: ${task.title}`)
						openInPopup(task)
					}}
				>
					<CompletedDays task={task} />
				</div>
				<div
					className={cls('assignee')}
					onClick={() => {
						// setPopupTitle(`Task: ${task.title}`)
						// openInPopup(task)
					}}
				>
					{image ? (
						// <button  >
						<Avatar
							className={cls('email-button')}
							src={image}
							sx={{ width: 30, height: 30 }}
							onClick={() => emailPartnersHandler(task)}
						/>
					) : (
						// </button>
						<Avatar {...stringAvatar(`${task.assignedToName}`)} />
					)}
				</div>
				{!hasSubtasks && (
					<button
						className={cls('delete-button')}
						onClick={() => {
							handleDeleteTask(task)
						}}
					>
						<RiCloseCircleLine />
					</button>
				)}
			</div>
			{hasSubtasks && subtasksAreVisible && <div className="Task__subtasks">{children}</div>}
			{!isSubtask && !task.isComplete && (
				<div
					className="Task__add-subtask"
					style={{ marginTop: hasSubtasks && subtasksAreVisible ? '0' : '-20px' }}
				>
					<Button
						className="Task__add-subtask-button"
						variant="ghost"
						onClick={() => {
							handelNewSubtask()
						}}
					>
						<RiAddLine />
						Add Subtask
					</Button>

					{hasSubtasks && (
						<Button
							className="Task__add-subtask-button"
							variant="ghost"
							onClick={() => {
								setSubtasksAreVisible(!subtasksAreVisible)
							}}
						>
							{subtasksAreVisible ? (
								<>
									<RiEyeOffLine /> Hide Subtasks
								</>
							) : (
								<>
									<RiEyeLine /> Show Subtasks
								</>
							)}
						</Button>
					)}
				</div>
			)}
			{openPopup && (
				<Popup title="{popupTitle}" openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<TaskAddEditPopup
						packageid={packageid}
						packageType={packageType}
						partners={partners}
						addEditTask={addEditTask}
						recordForEdit={recordForEdit}
						parentId={parentid}
					/>
				</Popup>
			)}
			{openAddPopup && (
				<Popup title={popupTitle} openPopup={openAddPopup} setOpenPopup={setOpenAddPopup}>
					<TaskAddPopup
						partners={partners}
						addTask={addEditTask}
						recordForEdit={recordForEdit}
						parentId={parentid}
					/>
				</Popup>
			)}{' '}
			{openEmailPopup && (
				<Popup title={popupTitle} openPopup={openEmailPopup} setOpenPopup={setOpenEmailPopup}>
					<SendEmailPopup sendEmailHandler={sendEmailHandler} emailKit={emailRecord} />
				</Popup>
			)}
		</StyledTask>
	)
}

export default Task
