import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import PopupRTE from 'components/PopupRTE'
import { Avatar, Grid } from '@mui/material'
import PostAddPopup from 'components/popups/PostAddPopup'
import convertRTEImages, { modifyNote } from 'utils/convertRTEImages'
import { uploadFile } from '@uploadcare/upload-client'
import Post from 'components/Post'
import { Create as CreateIcon } from '@mui/icons-material'
import FileUploader from 'components/FileUploader'
import { FaImage, FaVideo } from 'react-icons/fa'
import { createPost, createPostImage, initializePosts } from 'slices/postSlice'

const UPLOAD_CARE_PUBLIC_KEY = 'b4b06db04515dc9f76e0'

const ComunityPostsFeed = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	const userId = userInfo._id

	const {
		entities: postEntities,
		ids: postIds,
		isLoading: postsLoading,
		isInitialized: isCommunityPostsInitialized,
		error,
	} = useSelector(state => state.posts)
	const posts = postIds.map(id => postEntities[id])

	const POST_PROMPT = 'Start a post'
	const [input, setInput] = useState('')
	const [image, setImage] = useState('')
	const [video, setVideo] = useState('')

	const [openPopup, setOpenPopup] = useState(false)

	useEffect(() => {
		dispatch(initializePosts())
	}, [dispatch, isCommunityPostsInitialized])

	const sendPostImage = async info => {
		const elements = info.name.split('.')
		const imageExt = elements.slice(elements.length - 1).toString()

		setImage(info.secure_url)

		dispatch(
			createPostImage({
				userid: userInfo._id,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: imageExt,
			}),
		)
	}

	const addPost = async newNote => {
		setOpenPopup(false)

		const fileArray = convertRTEImages(newNote)

		const uploadedImages = []

		for (const file of fileArray) {
			const uploadedImage = await uploadFile(file, {
				publicKey: UPLOAD_CARE_PUBLIC_KEY,
				store: 'auto',
			})
			uploadedImages.push(uploadedImage)
		}

		const modifiedNote = modifyNote(newNote, uploadedImages)

		await dispatch(
			createPost({
				user: userInfo._id,
				message: modifiedNote,
			}),
		)

		for (const uploadedImage of uploadedImages) {
			await dispatch(
				createPostImage({
					userid: userId,
					uuid: uploadedImage.uuid,
					name: uploadedImage.name,
					size: uploadedImage.size,
					url: uploadedImage.cdnUrl,
					source: uploadedImage.source,
					type: uploadedImage.mimeType.split('/')[1],
				}),
			)
		}
		await dispatch(initializePosts())
	}

	const openInPopup = () => {
		setOpenPopup(true)
	}

	return (
		<div>
			<Grid container spacing={6}>
				<Grid item xs={1}></Grid>
				<Grid item xs={10}>
					<div className="feed">
						<div className="feed_inputContainer" style={{ display: `flex`, gridGap: '.5rem' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<Avatar src={userInfo.image}></Avatar>
							</div>
							<div style={{ flex: '1' }}>
								<form className="feed_newInput" onClick={openInPopup}>
									{` start a post . . .`}
									{error && <Message variant="danger">{error}</Message>}
								</form>
							</div>
						</div>
						<div className="feed_Container">
							{postsLoading ? (
								<Spinner />
							) : error ? (
								<Message variant="danger">{error}</Message>
							) : (
								<div>
									{posts.map(post => (
										<Post key={post._id} post={post} />
									))}
								</div>
							)}
						</div>
					</div>
				</Grid>
			</Grid>
			<PopupRTE title="" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<PostAddPopup addNote={addPost} />
			</PopupRTE>
		</div>
	)
}

export default ComunityPostsFeed
