import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const TaskAddPopup = ({ partners, addTask, recordForEdit, parentId }) => {
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const task = recordForEdit

	const initialValues = {
		...task,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.taskName = values.taskName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			addTask(values, resetForm)
		}
	}

	useEffect(() => {
		if (task != null) setValues({ ...task })
	}, [task, setValues])

	console.log(`partners: `, partners)
	console.log(`values: `, values)
	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TWControls.Input
						name="taskName"
						label="Task Name"
						value={values.taskName}
						onChange={handleInputChange}
						error={errors.taskName}
					/>
					<TWControls.Select
						name="assignedTo"
						label="Assigned To"
						value={values.assignedTo}
						onChange={handleInputChange}
						options={partners}
						error={errors.assignedTo}
					/>
					<TWControls.DatePicker
						name="completedBy"
						label="Due Date"
						value={values.completedBy ? values.completedBy : null}
						onChange={handleInputChange}
						error={errors.completedBy}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Add Task" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default TaskAddPopup
