import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Popup from 'components/Popup'
import moment from 'moment'
import {
	Avatar,
	Button,
	ButtonGroup,
	Checkbox,
	FormControlLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import { ChevronRight as ChevronRightIcon, LocationOn as LocationOnIcon } from '@mui/icons-material'

import {
	FaAppStore,
	FaBars,
	FaBuilding,
	FaFileAlt,
	FaFolderOpen,
	FaMapMarkerAlt,
	FaProjectDiagram,
	FaRegGrinAlt,
	FaRegSmileWink,
	FaSignOutAlt,
	FaTasks,
	FaTimes,
	FaTools,
	FaUserCircle,
	FaUsers,
} from 'react-icons/fa'

import PartnerShowPopup from 'components/popups/PartnerShowPopup'
import PortfolioBlank from 'images/Portfolio_Blank.png'
import Layout from 'components/Layout'
import Card from 'components/Card'
import * as _ from 'styles/helpers'
import { ChatHeaderListContainer } from 'features/Chat'
import { initializeActiveLocations } from 'slices/locationSlice'
import { initializePortfolios } from 'slices/portfolioSlice'
import { initializeAggregatedTasks, initializeAssignedTaskCount } from 'slices/tasksSlice'
import { initializeMyProjects } from 'slices/projectSlice'
import { initializeMyPartners } from 'slices/myPartnersSlice'

import { useChat, ChatHeaderList } from 'features/Chat'
import { getChatHeadersByUID } from 'slices/chatSlice'

const label = { inputProps: { 'aria-label': 'Switch demo' } }

const HomeWorking = () => {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [taskListIsPastDue, setTaskListIsPastDue] = useState(false)
	const [checked, setChecked] = useState(false)

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	const { entities: projectEntities, ids: projectIds } = useSelector((state: any) => state.projects)
	const projects = projectIds.map(id => projectEntities[id])

	const { entities: chats, ids: chatsds, isInitialized: chatsInitialized } = useSelector(
		(state: any) => state.chatHeaders,
	)
	const chatHeaders = chatsds.map(id => chats[id])

	const {
		entities: locations,
		ids: locationIds,
		isInitialized: locationsInitialized,
	} = useSelector((state: any) => state.locations)
	const locationsByDays = locationIds.map(id => locations[id])

	const {
		entities: portfolios,
		ids: portfolioIds,
		isInitialized: portfoliosInitialized,
	} = useSelector((state: any) => state.portfolios)
	const portfoliosByDays = portfolioIds.map(id => portfolios[id])

	const {
		entities: tasks,
		ids: taskIds,
		isLoading: tasksLoading,
		isInitialized: tasksInitialized,
	} = useSelector((state: any) => state.tasks)
	const aggregatedTasks = taskIds.map(id => tasks[id])

	const { taskCount: assignedTasks } = useSelector((state: any) => state.tasks.assignedTaskCount)
	const {
		entities: myPartners,
		ids: myPartnerIds,
		isInitialized: myPartnersInitialized,
	} = useSelector((state: any) => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])
	// const allPartners = myPartnerIds.map(id => myPartners[id])
	// const myPartnersList = allPartners.filter(partner => partner.isAccepted)

	useEffect(() => {
		if (!chatsInitialized) {
			dispatch(getChatHeadersByUID())
		}
		if (!locationsInitialized) {
			dispatch(initializeActiveLocations())
		}

		if (!myPartnersInitialized) {
			dispatch(initializeMyPartners())
		}

		if (!portfoliosInitialized) {
			dispatch(initializePortfolios())
		}

		dispatch(initializeMyProjects())
		dispatch(initializeAssignedTaskCount({ userId: userId }))

		if (taskListIsPastDue) {
			dispatch(
				initializeAggregatedTasks({
					userId: userId,
					backDays: 1000,
					forwardDays: 0,
				}),
			)
		} else {
			dispatch(
				initializeAggregatedTasks({
					userId: userId,
					backDays: 0,
					forwardDays: 30,
				}),
			)
		}
	}, [
		dispatch,
		myPartnersInitialized,
		user,
		userId,
		taskListIsPastDue,
		locationsInitialized,
		portfoliosInitialized,
	])

	const CompletedDays = ({ completedBy }) => {
		const timezoneOffset = new Date().getTimezoneOffset() / 60
		const hoursOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'hours')
		let daysOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'days')

		if (hoursOut < 0) {
			daysOut = daysOut - 1
		}

		const daysLable = daysOut > 1 || daysOut < -1 ? `days` : `day`
		const result = daysOut === 0 ? 'Today' : `${daysOut} ${daysLable}`

		return <div style={{ color: daysOut < 0 ? 'red' : 'inherit' }}>{result}</div>
	}

	const handleChange1 = e => {
		setChecked(e.target.checked)
	}

	const handelPartnerPopup = async partner => {
		setRecordForEdit(partner)
		setOpenPopup(true)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<Layout.SidebarUserCard />
					<StyledSidebar>
						<div className="SidebarContent">
							{myPartnersList.length > 0 ? (
								<Card
									className="SidebarContent__card SidebarContent__card--partners"
									actions={<Link to="/partners">View all partners &rarr;</Link>}
								>
									<div className="SidebarContent__card-header">
										<h2>
											<FaUsers /> Partners
										</h2>
									</div>
									<div className="SidebarContent__card-body">
										{myPartnersList && myPartnersList.length > 0 && (
											<List dense disablePadding>
												{myPartnersList.map((partner, index) => (
													<ListItem
														key={index}
														button
														component="a"
														onClick={() => {
															handelPartnerPopup(partner.partner)
														}}
													>
														<ListItemAvatar>
															<Avatar src={partner.partner.image} />
														</ListItemAvatar>
														{partner.isAccepted ? (
															<ListItemText
																primary={partner.partner.name}
																secondary={partner.partner.company}
															/>
														) : (
															<ListItemText primary={partner.partner.name} secondary="Pending" />
														)}
													</ListItem>
												))}
											</List>
										)}
									</div>
								</Card>
							) : (
								<Card
									className="SidebarContent__card SidebarContent__card--partners"
									actions={<Link to="/partners">Connect with a new partner &rarr;</Link>}
								>
									<div className="SidebarContent__card-header">
										<h2>
											<FaUsers /> Partners
										</h2>
									</div>
									<div className="SidebarContent__card-empty-partner">
										<Link to="/partners" style={{ textDecoration: 'none' }}>
											<h3>Add Partners to build Deal Teams for full collaboration.</h3>
										</Link>
									</div>
								</Card>
							)}
						</div>
					</StyledSidebar>
				</Layout.Sidebar>
				<Layout.Body>
					<StyledDashboard>
						<div className="DashboardContent">
							{assignedTasks > 0 ? (
								<Card
									className="DashboardContent__card DashboardContent__card--tasks"
									actions={<Link to="/tasks">View all tasks &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaTasks /> Tasks
										</h2>
										<div>
											<FormControlLabel
												label={checked ? 'My Tasks' : 'All Tasks'}
												control={
													<Checkbox size="small" checked={checked} onChange={handleChange1} />
												}
											/>
										</div>
										<div>
											<ButtonGroup disableElevation size="small" color="primary">
												<Button
													color="primary"
													variant={!taskListIsPastDue ? 'contained' : 'outlined'}
													onClick={() => {
														setTaskListIsPastDue(false)
													}}
												>
													This Week
												</Button>
												<Button
													color="primary"
													variant={taskListIsPastDue ? 'contained' : 'outlined'}
													onClick={() => {
														setTaskListIsPastDue(true)
													}}
												>
													Past Due
												</Button>
											</ButtonGroup>
										</div>
									</div>
									<div className="DashboardContent__card-body">
										{!tasksLoading && (
											<>
												{tasksInitialized ? (
													<Table size="small">
														<TableHead>
															<TableRow>
																<TableCell align="center" width="110px">
																	Assigned
																</TableCell>
																<TableCell>Task</TableCell>
																<TableCell>Location</TableCell>
																<TableCell align="right">Due</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{aggregatedTasks.map(task => (
																<TableRow
																	className="DashboardContent__task-row_link"
																	key={task._id}
																	component="a"
																	href={
																		task.projectid
																			? `/locations/${task.packageid._id}/projects/${task.projectid}/tasks`
																			: `/portfolios/${task.portfolioid._id}/tasks`
																	}
																	style={{ textDecoration: 'none' }}
																>
																	<TableCell
																		align="center"
																		style={{ display: 'flex', justifyContent: 'center' }}
																	>
																		<Avatar src={task.assignedTo.image}>
																			{task.assignedTo.image}
																		</Avatar>
																	</TableCell>
																	<TableCell className="DashboardContent__task-row_link">
																		{task.taskName}
																	</TableCell>
																	<TableCell>
																		{task.packageid
																			? task.packageid.assetName
																			: task.portfolioid.assetName}
																	</TableCell>
																	<TableCell align="right">
																		<CompletedDays completedBy={task.completedBy} />
																	</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												) : (
													<div className="DashboardContent__card-empty">
														<h3>No Tasks</h3>
													</div>
												)}
											</>
										)}
									</div>
								</Card>
							) : (
								<Card
									className="DashboardContent__card DashboardContent__card--tasks"
									actions={<Link to="/tasks">View all tasks &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaTasks /> Tasks
										</h2>
									</div>
									<div className="DashboardContent__card-empty">
										{projects && projects.length > 0 ? (
											<div>
												<h3>Select a Project below to create an initial task Task.</h3>
												<List dense disablePadding>
													{projects.map((project, index) => (
														<ListItem
															key={index}
															button
															component="a"
															href={`/locations/${project.projectAsset._id}/projects/${project._id}/tasks`}
														>
															<ListItemAvatar>
																<Avatar src={project.image} />
															</ListItemAvatar>
															<ListItemText
																primary={`${project.projectAsset.assetName}: ${project.projectName}`}
																secondary={project.projectDescription}
															/>
															<IconButton edge="end" aria-label="delete">
																<ChevronRightIcon />
															</IconButton>
														</ListItem>
													))}
												</List>
											</div>
										) : (
											<Link to="/locations" style={{ textDecoration: 'none' }}>
												<h3>Create and assign Tasks to Projects within a Location.</h3>
											</Link>
										)}
									</div>
								</Card>
							)}
							{chatHeaders.length > 0 ? (
								<Card
									className="DashboardContent__card DashboardContent__card--chats"
									actions={<Link to="/chats">View all chats &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<ChatIcon /> Chats
										</h2>
										<p>Most recent</p>
									</div>
									<div className="DashboardContent__card-body">
										<ChatHeaderListContainer isLink showSelected={false} />
									</div>
								</Card>
							) : (
								<Card
									className="DashboardContent__card DashboardContent__card--chats"
									actions={
										myPartnersList.length > 0 && <Link to="/chats">Start a new chat &rarr;</Link>
									}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<ChatIcon /> Chats
										</h2>
									</div>
									<div className="DashboardContent__card-empty">
										<Link to="/chats" style={{ textDecoration: 'none' }}>
											<h3>Connect with one or more Partners and interact with them via Chat.</h3>
										</Link>
									</div>
								</Card>
							)}
							{locationsByDays.length > 0 ? (
								<Card
									className="DashboardContent__card DashboardContent__card--locations"
									actions={<Link to="/locations">View all locations &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaMapMarkerAlt /> Locations
										</h2>
										<p>Last 30 days</p>
									</div>
									<div className="DashboardContent__card-body">
										{locationsByDays && locationsByDays.length > 0 ? (
											<List dense disablePadding>
												{locationsByDays.map((location, index) => (
													<ListItem
														key={index}
														button
														component="a"
														href={`/locations/${location._id}/projects`}
													>
														<ListItemAvatar>
															{location.image ? (
																<Avatar src={location.image} />
															) : (
																<Avatar>
																	<LocationOnIcon />
																</Avatar>
															)}
														</ListItemAvatar>
														<ListItemText
															primary={`${
																location.retailer ? location.retailer : location.assetName
															} - ${`${location.city}, ${location.stateProvince}`}`}
															secondary={location.updateNote}
														/>
														<ListItemSecondaryAction className="secondary-action">
															<IconButton edge="end" aria-label="delete">
																<ChevronRightIcon />
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												))}
											</List>
										) : null}
									</div>
								</Card>
							) : (
								<Card
									className="DashboardContent__card DashboardContent__card--locations"
									actions={<Link to="/locations">Create a new Location &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaMapMarkerAlt /> Locations
										</h2>
									</div>
									<div className="DashboardContent__card-empty">
										<Link to="/locations" style={{ textDecoration: 'none' }}>
											<h3>
												Start by adding Locations to manage Projects and related Documents, Images,
												Notes, ect.
											</h3>
										</Link>
									</div>
								</Card>
							)}
							{projects.length > 0 ? (
								<Card
									className="DashboardContent__card DashboardContent__card--projects"
									actions={<Link to="/projects">View all projects &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaProjectDiagram /> Projects
										</h2>
									</div>
									<div className="DashboardContent__card-body">
										{projects && projects.length > 0 ? (
											<List dense disablePadding>
												{projects.map((project, index) => (
													<ListItem
														key={index}
														button
														component="a"
														href={`/locations/${project.projectAsset._id}/projects/${project._id}/notes`}
													>
														<ListItemAvatar>
															<Avatar src={project.image} />
														</ListItemAvatar>
														<ListItemText
															primary={`${project.projectAsset.assetName}: ${project.projectName}`}
															secondary={project.projectDescription}
														/>
														<IconButton edge="end" aria-label="delete">
															<ChevronRightIcon />
														</IconButton>
													</ListItem>
												))}
											</List>
										) : null}
									</div>
								</Card>
							) : (
								<Card
									className="DashboardContent__card DashboardContent__card--projects"
									actions={<Link to="/locations">Create a new Project &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaProjectDiagram /> Projects
										</h2>
									</div>
									<div className="DashboardContent__card-empty">
										<Link to="/locations" style={{ textDecoration: 'none' }}>
											<h3>Add Projects to Locations to manage Documents, Images, Notes, ect.</h3>
										</Link>
									</div>
								</Card>
							)}
							{portfoliosByDays.length > 0 ? (
								<Card
									className="DashboardContent__card DashboardContent__card--portfolios"
									actions={<Link to="/portfolios">View all portfolios &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaFolderOpen /> Portfolios
										</h2>
										<p>Last 30 days</p>
									</div>
									<div className="DashboardContent__card-body">
										{portfoliosByDays && portfoliosByDays.length > 0 && (
											<List dense disablePadding>
												{portfoliosByDays.map((portfolio, index) => (
													<ListItem
														key={index}
														button
														component="a"
														href={`/portfolios/${portfolio._id}`}
													>
														<ListItemAvatar>
															{portfolio.image ? (
																<Avatar src={portfolio.image} />
															) : (
																<Avatar src={PortfolioBlank} />
															)}
														</ListItemAvatar>
														<ListItemText
															primary={portfolio.assetName}
															secondary={portfolio.updateNote}
														/>
														<ListItemSecondaryAction className="secondary-action">
															<IconButton edge="end" aria-label="delete">
																<ChevronRightIcon />
															</IconButton>
														</ListItemSecondaryAction>
													</ListItem>
												))}
											</List>
										)}
									</div>
								</Card>
							) : (
								<Card
									className="DashboardContent__card DashboardContent__card--portfolios"
									actions={<Link to="/portfolios">Create a new Portfolio &rarr;</Link>}
								>
									<div className="DashboardContent__card-header">
										<h2>
											<FaFolderOpen /> Portfolios
										</h2>
									</div>
									<div className="DashboardContent__card-empty">
										<Link to="/portfolios" style={{ textDecoration: 'none' }}>
											<h3>
												Create Portfolios to help you track and manage multiple locations
												collectively.
											</h3>
										</Link>
									</div>
								</Card>
							)}
						</div>
					</StyledDashboard>
				</Layout.Body>
			</Layout.Page>
			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<PartnerShowPopup recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</Layout>
	)
}

const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
			height: ${_.rem(519)};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-body {
			height: 100%;
			max-height: ${_.rem(390)};
			overflow-y: scroll;
		}

		&__task-link {
			height: 100%;
			max-height: ${_.rem(390)};
			overflow-y: scroll;

			h3 {
				font-size: ${_.rem(22)};
				font-weight: 400;
				color: ${_.COLORS.tradewind_blue};
			}

			h3:hover {
				font-weight: 500;
				cursor: pointer;
			}
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card-empty-partner {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				text-align: center;
				font-size: ${_.rem(22)};
				font-weight: 400;
				color: ${_.COLORS.tradewind_blue};
				margin: ${_.rem(16)};
				padding: ${_.rem(6)};
				border-radius: ${_.rem(15)};
			}

			h3:hover {
				font-weight: 500;
				cursor: pointer;
			}
		}

		&__card {
			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

const StyledDashboard = styled.div`
	.DashboardHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.DashboardContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-body {
			height: 100%;
			max-height: ${_.rem(240)};
			overflow-y: scroll;
		}

		&__card-empty {
			${_.MIXINS.vhc};
			min-height: ${_.rem(246)};
			height: 100%;

			h3 {
				text-align: center;
				font-size: ${_.rem(22)};
				font-weight: 300;
				font-weight: 400;
				color: ${_.COLORS.tradewind_blue};
				/* color: rgba(0, 0, 0, 0.54); */
				padding-left: ${_.rem(22)};
				padding-right: ${_.rem(22)};
			}
			h3:hover {
				font-weight: 500;
				cursor: pointer;
			}
		}

		&__card {
			min-height: ${_.rem(360)};

			&--tasks {
				grid-column: col / span 8;
			}
			&--chats {
				grid-column: col 9 / span 4;
			}
			&--locations {
				grid-column: col / span 4;
			}
			&--projects {
				grid-column: col 5 / span 4;
			}
			&--portfolios {
				grid-column: col 9 / span 4;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}

		&__card_top {
			height: ${_.rem(140)};

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}

		&__task-row_link {
			/* text-decoration: none; */
			&:hover {
				font-weight: 600;
				text-decoration: none;
				background: rgba(255, 255, 255, 0.125);
				color: ${_.COLORS.tradewind_blue} !important;
			}
		}
	}
`

export default HomeWorking
